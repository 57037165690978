import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';


/**
 * Loaders
 */
const loader = new GLTFLoader();
const cubeTextureLoader = new THREE.CubeTextureLoader();
const loadingManager = new THREE.LoadingManager();
const TextureLoader = new THREE.TextureLoader();

const width = 280;
const height = 440;
const sizes = {
    width: width,
    height: height
};

const options = {
    enableSwoopingCamera: false,
    enableRotation: false,
    color: 0xffffff,
    metalness: 0,
    roughness: 0.2,
    transmission: 1,
    ior: 1.5,
    reflectivity: 0.01,
    thickness: 5,
    clearcoat: 1,
    clearcoatRoughness: 0.1,
    normalScale: 0.3,
    clearcoatNormalScale: 0.2,
    normalRepeat: 3,
    bloomThreshold: 0.85,
    bloomStrength: 0.35,
    bloomRadius: 0.33,
};

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/0/px.jpg',
    '/textures/environmentMaps/0/nx.jpg',
    '/textures/environmentMaps/0/py.jpg',
    '/textures/environmentMaps/0/ny.jpg',
    '/textures/environmentMaps/0/pz.jpg',
    '/textures/environmentMaps/0/nz.jpg'
]);

// const etiquetteMap = TextureLoader.load([
//     '../textures/Aubergine.png'
// ]);
// const etiquette = new THREE.MeshStandardMaterial({
//     map: etiquetteMap
// });

const material = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: options.metalness,
    roughness: options.roughness,
    transmission: options.transmission,
    ior: options.ior,
    reflectivity: options.reflectivity,
    thickness: options.thickness,
    envMapIntensity: options.envMapIntensity,
    clearcoat: options.clearcoat,
    clearcoatRoughness: options.clearcoatRoughness,
    normalScale: new THREE.Vector2(options.normalScale),
    // transparent: options.transparent,
});


function createScene() {
    const scene = new THREE.Scene();
    environmentMap.encoding = THREE.sRGBEncoding;
    const color1 = new THREE.Color();
    // scene.background = color1;
    scene.environment = environmentMap;


    // const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const camera = new THREE.PerspectiveCamera(40, sizes.width / sizes.height, 0.1, 100);
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    // renderer.setSize(width, height);
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.physicallyCorrectLights = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 5.14;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // camera.position.z = 5;
    camera.position.set(150, 10, 12);
    scene.add(camera);

    let controls = new OrbitControls(camera, renderer.domElement);
    controls.enablePan = false;
    controls.enableZoom = true;
    controls.minDistance = 2;
    controls.maxDistance = 12;
    controls.minPolarAngle = Math.PI / 3;
    controls.maxPolarAngle = Math.PI / 2;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 2;

    loadBackground('https://i.postimg.cc/pTG13mRG/Capture-d-cran-2023-07-19-141100.png', scene);

    animate(scene, camera, renderer, controls);

    return { scene, camera, renderer, controls };
}

function loadModel(url, scene, camera) {
    /**
    * Light
    */
    const directionalLight = new THREE.DirectionalLight('#ffffff', 0.527);
    directionalLight.position.set(1.436, 0.698, 0.329);
    directionalLight.castShadow = true;
    directionalLight.shadow.camera.far = 15;
    directionalLight.shadow.mapSize.set(1024, 1024);
    directionalLight.shadow.normalBias = 0.023;
    directionalLight.shadow.Bias = -0.004;
    camera.add(directionalLight);

    // let light = new THREE.AmbientLight(0xFFFFFF, 0.1);
    // scene.add(light);

    console.log(url);
    loader.load(url,
        (glb) => {
            console.log('loaded');
            glb.scene.scale.set(1, 1, 1);
            glb.scene.position.set(0, -3, 0);
            glb.scene.rotation.y = Math.PI * 0.5;

            glb.scene.children.forEach((child) => {
                child.castShadow = false;
                child.receiveShadow = true;

                if (child.name === "VERRE") {
                    child.material = material;
                }

            });

            scene.add(glb.scene);

        },
        undefined,
        function (error) {
            console.error('ERR', error);
        }
    );
}

function loadBackground(url, scene) {
    let textureLoader = new THREE.TextureLoader();
    textureLoader.load(url,
        function (texture) {
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(250 / 76, 250 / 129);
            scene.background = texture;
        },
        undefined,
        function (error) {
            console.error(error);
        }
    );
}

function animate(scene, camera, renderer, controls) {
    function loop() {
        requestAnimationFrame(loop);
        controls.update();
        renderer.render(scene, camera);
    }
    loop();
}

export function createAnimTreejs(url) {
    const li = document.createElement('li');
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.zIndex = -1;
    div.style.top = 0;
    div.style.left = 0;

    const { scene, camera, renderer, controls } = createScene();
    loadModel(url, scene, camera);

    li.appendChild(renderer.domElement);
    li.style.width = width;
    li.style.height = height;

    li.addEventListener('mouseover', function () {
        controls.enabled = true;
    });

    li.addEventListener('mouseout', function () {
        controls.enabled = false;
    });

    li.appendChild(div);
    document.getElementById('productTarget').appendChild(li);
}

for (let url of [
    '/models/Aubergine.glb',
    '/models/Aubergine.glb',
    '/models/Aubergine.glb'
]) {
    createAnimTreejs(url);
}
